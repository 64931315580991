import { defineComponent as __define__ } from 'vue'
import { Slots as __Slots__ } from 'vue'
import SunBurstView from "@/components/SunBurstView.vue";


export function setup() {

const getContent = (selected: SunBurstNode) => {
  if (selected?.parent?.parent?.parent) {
    const end =
      selected.parent.parent.parent.data.shortname === "ud"
        ? "mfa"
        : `${selected.parent.parent.parent.data.shortname}.dep`;
    const email = `${selected.data.firstname}.${selected.data.lastname}@${end}.no`.toLowerCase();

    return `<div class="tw-text-center tw-leading-loose"><p class="tw-text-xl">${selected.data.title}</p><p>${selected.parent.parent.parent.data.name}</p><p>${selected.parent.parent.data.name}</p><p>${selected.parent.data.name}</p><p><a tw-href="mailto:${email}">${email}</a></p><p><a href="tel:${selected.data.phone}">${selected.data.phone}</a></p><p class="tw-my-8"><a tw-class="tw-bg-white tw-border tw-px-4 tw-py-2 tw-text-black tw-rounded tw-no-underline hover:tw-bg-gray-dark hover:tw-text-white hover:tw-border-gray-dark" href="mailto:?subject=Content details of ${selected.data.name}&body=Please find the contact details of ${selected.data.name} at this link: ${window.location}.">Share</a></p></div>`;
  }

  return "";
};

const open = (item: SunBurstNode) => {
  window.open(`mailto:${item.data.email}`);
};

/**
 * Current data.
 */
const data = {
  name: "Departementene",
  children: require("@/assets/data/norwegian-employees-transformed.json")
};


return { getContent, open, data }
}

const __default__ = { components: { SunBurstView } };
export default __define__({
  ...__default__,
  setup
})